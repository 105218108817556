import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { validarEntorno } from '../../helpers/validarHost';
import { useParams } from 'react-router-dom';
import Loader from '../icons/Loader';
import './InformePanoramaRiesgos.scss';
import FormDinamico from './Form';
import FormularioArchivos from './FormularioArchivos';
import Mapa from "./Mapa";
import { ContainerD } from "../../components/dashboard/Container";
import ReportesOperativos from '../../components/ReportesOperativos/Main'
import GestionAuditorias from '../gestionAuditorias/Dashboard/GestionAuditorias';
import Dofa from '../gestionAuditorias/DOFA/Dofa';
import MatrizRiesgosOperacionesDinamico from '../gestionAuditorias/MatrizRiesgos/MatrizRiesgosOperacionesDinamico';
import ArbolDinamico from '../gestionAuditorias/PlanEstrategico/ArbolDinamico';
import Filtros from './Filtros'
import { EventBusProvider } from '../../context/EventBus';
import { useEventBus } from '../../context/EventBus';
import { t } from 'i18next';

/**
 * Componente hijo del gestor dinamico, este hara una peticion de los datos
 * gestionados y mediante otro componente se realizara la maquetación del formulario
 */
const FormularioDinamico = ({ idSegundoNivel, titulo, idTercerNivel = '', subseccionCampo = '', datosSolicitud={}, handleEnviar=()=>{}, seccionPt }) => {

  const eventBus = useEventBus();
  const [inputsGroup, setInputsGroup] = useState([]); //UseStateditare que almacena la lista de campos
  const [esMultiple, setEsMultiple] = useState(false); // Se valida si el formulario es multiple
  const [esEditable, setEsEditable] = useState(false); // Se valida si el formulario se puede
  const [botonEnviar, setBotonEnviar] = useState(false); // Se valida si el formulario puede mostar el boton
  const [botonIA, setBotonIA] = useState(false); // Se valida si el formulario puede mostar el boton de IA
  const [nombreForm, setNombreForm] = useState(); // Nombre del formulario, según los datos que tenga.
  const [tabla, setTabla] = useState([]); // Indica la tabla que se va a mostrar en formularios múltiples
  const [tipoContenido, setTipoContenido] = useState(); // Validamos el tipo de contenido que va tener el formulario
  const [data, setData] = useState([]); // Almacenamos los datos de la petición
  const [filtros, setFiltros] = useState([]); // Almacenamos los datos de la petición
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { seccion: seccionParams , id } = useParams();
  const seccion = seccionParams || seccionPt;
  //console.log('id', id, 'seccion', seccion)
  const hash = window.location.hash;
  const [isLoading, setIsLoading] = useState(false); // Estado para mostrar el loader

  useEffect(() => {
    cleanEstructure();
    getEstructura();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSegundoNivel])

  useEffect(() => {

  }, [])
  //estado bandera para que el boton solo pueda ser accionado una vez

  const [changeB, setChangeB] = useState(false);

  function click() {
    setChangeB(true);
    return;
  }

  useEffect(() => {
    setTimeout(() => { setChangeB(false) }, 20000)
  }, [changeB])

  /**
   * Dependiendo los datos de seccion y el id del segundo nivel
   * se armaran los datos de petición y se enviaran al backend
   * despues si la respuesta ha sido positiva se empezaran a llenar las
   * variables tanto el arreglo de campos (inputsGroup), la validacion
   * de varios formularios (esMultiple), asi como el tipo de contenido
   */
  const getEstructura = async (filtro, filtros, aditionalData) => {
    console.log('GET ESTRUCTURA FILTRO', filtro);
    console.log("GET ESTRUCTURA FILTROS****", filtros);
    console.log("GET ESTRUCTURA ADITIONALDATA****", aditionalData);
    setIsLoading(true);
    let dataReq = {
      id,
      seccion,
      id_segundo_nivel: idSegundoNivel,
      hash,
    }

    if (idTercerNivel) {
      dataReq.id_tercer_nivel = idTercerNivel;
    }

    if (subseccionCampo) {
      dataReq.subseccion_campo = subseccionCampo;
    }

    if (filtro) {
      dataReq.limite = filtro.limite;
      dataReq.pagina = filtro.pagina;
      dataReq.busqueda = filtro.busqueda;
    }

    if (filtros) {
      dataReq.filtros = filtros;
    }

    if (aditionalData) {
      dataReq = { ...dataReq, ...aditionalData }
    }

    if(datosSolicitud){
      dataReq = {...dataReq, ...datosSolicitud}
    }

    //console.log("Solicitud de formulario",dataReq);
    let req = '';

    if (hash.includes('#/gerencia/inteligecia/gestor/creada/')) {
      if (idSegundoNivel === 6) {
        req = await validarEntorno('JSON/GestionDinamica/gestion_datos_formulario_col1.php', 'POST', dataReq);
      } else if (idSegundoNivel === 7) {
        req = await validarEntorno('JSON/GestionDinamica/gestion_datos_formulario_col2.php', 'POST', dataReq);
      }
    }

    req = await validarEntorno('JSON/GestionDinamica/gestion_datos_formulario.php', 'POST', dataReq);

    if (req.ok) {
      const res = await req.json();
      setInputsGroup(res.tercer_nivel);
      setEsMultiple(res.es_multiple);
      setBotonEnviar(res.btn_enviar);
      setTabla(res.tabla);
      setData(res.data);
      setFiltros(res.filtros);
      setBotonIA(res?.boton_ia);

      /**
       * Cuatro tipos de contenido
       * 1. Formularios de gestion.
       * 2. Tabla de con datos involucrados en la gestión
       * 3. Formulario de archivos masivos
       * 4. Formulario con mapa para establecer ubicaciones.
       */
      setTipoContenido(res.tipo_contenido);

      // Validamos si el formulario es editable, es decir sus campos estan hailitados para insercion o actualización
      if (!res.es_multiple) {
        setEsEditable(res?.tercer_nivel?.[0]?.es_editable);
      }

      setNombreForm(res.nombre_form);
    }
    setIsLoading(false);
  }


  /**
   * Funcion para limpiar el formulario, en caso de cambio
   * del subsistema o sistema
   */
  const cleanEstructure = () => {
    setInputsGroup([]);
    setTipoContenido('');
    setEsMultiple(false);
    setBotonEnviar(false)
  }
  /**
   * Funcion que dependiendo del contenido regresara alguno de los contenidos
   * establecidos anteriormente.
   * @returns
   */
  const getStructure = () => {
    console.log("tipo "+ tipoContenido);
    switch (tipoContenido) {
      case 'form':
        if (inputsGroup && inputsGroup.length > 0) {
          return (
            inputsGroup.map((inputs, index) => {
              const fieldName = `asesoria[${index}]`;
              return (
                <FormDinamico
                  click={click}
                  changeB={changeB}
                  setChangeB={setChangeB}
                  idSegundoNivel={idSegundoNivel}
                  titulo={titulo}
                  inputs={inputs}
                  index={index}
                  esMultiple={esMultiple}
                  esEditable={esEditable}
                  nombreForm={nombreForm}
                  botonEnviar={botonEnviar}
                  fieldName={fieldName}
                  len={inputsGroup.length}
                  setInputsGroup={setInputsGroup}
                  getEstructura={getEstructura}
                  inputsGroup={inputsGroup}
                  idTercerNivel={idTercerNivel}
                  subseccionCampo={subseccionCampo}
                  tabla={tabla}
                  handlePaginacion={getEstructura}
                  botonIA={botonIA}
                  datosSolicitud={datosSolicitud}
                  handleEnviar={handleEnviar}
                  seccionPt={seccionPt}
                />
              )
            })
          )
        } else {
          return (<Loader />)
        }
      case 'tabla':
        return (
          <ReportesOperativos aditionalData={{
            id: id,
            seccion: seccion,
            idSegundoNivel: idSegundoNivel
          }} />
        )
      case 'archivos_masivo':
        return (<FormularioArchivos idSegundoNivel={idSegundoNivel} />)
      case 'mapa':
        if (inputsGroup[0]) {
          return (<Mapa idSegundoNivel={idSegundoNivel} item={inputsGroup[0].campos[0]} cleanEstructure={cleanEstructure} getEstructura={getEstructura} />)
        }
        break;
      case 'panel':
        return <ContainerD />
      case 'areas_diagnostico':
        return <GestionAuditorias type={2} />
      case 'dofa':
        return <Dofa />
      case 'matriz_riesgos':
        return <MatrizRiesgosOperacionesDinamico />
      case 'arbol':
        console.log("Arbol");
        return <ArbolDinamico data={data} getEstructura={getEstructura} idSegundoNivel={idSegundoNivel} isLoading={isLoading} />
      default:
        return (<Loader />)
    }
  }

  /**
   * Consumimos el evento de los filtros para realizar la petición de los datos nuevamente
   * con los filtros actualizados, cuando se emite el evento se ejecuta la función
   */

  useEffect(() => {
    const handleCallback = (filtros) => {
      console.log('FILTROS ACTUALIZADOS', filtros);
      console.log('TIPO DE CONTENIDO', tipoContenido);
      if (tipoContenido === 'form' || tipoContenido === 'arbol') {
        console.log('ES FORM O ARBOL');
        getEstructura(undefined, filtros);
      }
    }
    eventBus?.subscribe('FILTROS_ACTUALIZADOS', handleCallback);
    return () => {
      eventBus?.unsubscribe('FILTROS_ACTUALIZADOS', handleCallback);
    };
  }, [eventBus, tipoContenido]);


  /**
   * Cargamos el componente
   */
  return (
    <>
      <section style={{ margin: 'auto' }} id="contenedorFormulario">
        <Row className='gx-0'>
          <div className='headerRegistro mb-3 rounded-xl'>
            <p className='' >{titulo}</p>
          </div>
          {filtros && filtros.length > 0 && <Filtros filtros={filtros} classname={"mb-3"} />}
        </Row>
        <Row className='gx-0'>
          {getStructure()}
        </Row>
      </section>
    </>
  )
}

export default FormularioDinamico;
