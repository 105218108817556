import { Col, Form } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';
import { DropzoneArea } from "material-ui-dropzone";
import { getBase64, getFile, toArrayBuffer } from "../fileToBase64";
import { useState } from "react";
import { AttachFile, PictureAsPdf } from '@material-ui/icons';
import './styles/controles.scss';
import TranscriptionButton from "../../components/common/TranscriptionButton";


const Dropzone = ({ item, handleInputChange, index, idx, reg, nombre, esEditable, esCargueIndividual }) => {

    const { control } = useForm();

    const [data, setData] = useState({ imagen: item.valor ? item.valor : '' });

    /**
     * Funcion que convierte el archivo en base 64
     * @param {*} file
     */
    const handleImageData = async (file) => {
        if (file.length > 0 && typeof (file) === 'object') {
            const imageB64 = await getBase64(file[0]);

            setData({
                ...data,
                imagen: imageB64
            });
        }
    }

    /**
     * Funcion que permite acceder al recurso en tres casos:
     * 1. Un base 64.
     * 2. Un recurso que proviene del servidor de cosinte
     * 3. Un recurso externo
     * @param {*} uri
     */
    const openDataUri = (uri) => {
        console.log(uri);

        if (uri.startsWith('data:')) {
            const type = uri.split(';')[0].split('/')[1].toLowerCase();

            let sourceWindow = window.open("");

            if (type !== 'pdf') {
                sourceWindow.document.write(
                    "<iframe width='100%' height='100%' src=" + encodeURI(uri) + " height='768' width='1024'></iframe>"
                )
            } else {
                sourceWindow.document.write(
                    "<iframe width='100%' height='100%' src=" + encodeURI(uri) + "></iframe>"
                )
            }
        } else {

            const typeUrl = uri.split('.');
            const extension = typeUrl[typeUrl.length - 1].toLowerCase();

            if (typeUrl[1] === 'ciacosinte') {

                let sourceWindow = window.open("");

                if (extension !== 'pdf') {
                    sourceWindow.document.write(
                        "<iframe width='100%' height='100%' src=" + encodeURI(uri) + " height='768' width='1024'></iframe>"
                    )
                } else {
                    sourceWindow.document.write(
                        "<iframe width='100%' height='100%' src=" + encodeURI(uri) + "></iframe>"
                    )
                }
            } else {
                window.open(uri);
            }
        }
    }

    /**
     * Funcion que genera el recurso (enlace) del archivo
     * sin depender de donde provenga
     * @param {*} file
     * @returns
     */
    const getTypeFile = (file) => {
        if (typeof (file) === 'string') {
            if (file.startsWith('data:')) {
                const type = file.split(';')[0].split('/')[1].toLowerCase();
                return type;
            } else if (file.startsWith('https:') || file.startsWith('http:')) {
                const typeUrl = file.split('.');

                if (typeUrl[1] === 'ciacosinte') {
                    const typeUrl = file.split('.');
                    const extension = typeUrl[typeUrl.length - 1].toLowerCase();
                    return extension;
                } else {
                    return 'external_resource';
                }
            }
        }
    }

    /**
     * Funcion que muestra un tipo de archivo
     * dependiendo si es un pdf u otro tipo de extensión
     * @param {*} fileObject
     * @param {*} classes
     * @returns
     */
    const handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file;

        const iconProps = {
            className: classes.image,
        }

        switch (type) {
            case "application/pdf":
                return <PictureAsPdf {...iconProps} />
            default:
                return <AttachFile {...iconProps} />
        }
    }

    return (
        <Form.Group style={{ display: 'block' }}>
            <Form.Label>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
            <div id='dropzoneVertical' style={{ display: 'flex', flexDirection: 'inherit' }}>
                {!esEditable ? (
                    <div style={{ width: '60%', marginRight: '0.5em' }}>
                        <Controller
                            control={control}
                            name={`${nombre}.${item.nombre}`}
                            defaultValue={((!item.valor.startsWith('http')) && (item.valor !== "" && item.valor !== " ")) ? [item.valor] : null}
                            render={({ onChange, value }) => (
                                <DropzoneArea
                                    ref={reg}
                                    dropzoneText=""
                                    name={`asesoria.${index}.${item.nombre}`}
                                    onChange={(e) => onChange(handleImageData(e))}
                                    filesLimit={1}
                                    showAlerts={false}
                                    initialFiles={value}
                                    getPreviewIcon={handlePreviewIcon}
                                    //onDelete={(file) => { deleteImageData(file) }}
                                    // dropzoneClass={validatedFile ? 'validated-dropzone' : null}
                                    dropzoneClass="dropzone-dinamyc-class"
                                    showPreviewsInDropzone={false}
                                    dropzoneProps={{ disabled: esEditable }}
                                    style={{ width: '50%' }}
                                    error
                                    maxFileSize={350000000}
                                />
                            )}
                        />
                    </div>
                ) : null}
                {/* Dependiendo del tipo de recurso puede cargar:
                  * 1. Una imagen previa del recurso
                  * 2. Una enlace a PDF.
                  * 3. Un reproductor de audio.
                  * 4. Un enlace a un recurso externo
                 */}
                <div style={{ width: '100%', marginTop: '10px' }}>
                    {data.imagen !== '' ? ((getTypeFile(data.imagen) === 'jpeg' || getTypeFile(data.imagen) === 'png' || getTypeFile(data.imagen) === 'jpg' || getTypeFile(data.imagen) === 'gif' ? (
                        <div className="container-previous-image" onClick={() => { openDataUri(data.imagen) }}>
                            <img className="previous-image" width="100%" height="100%" src={data.imagen} alt="" />
                            <span className="previous-content">Ver imagen</span>
                        </div>
                    ) : (getTypeFile(data.imagen) === 'pdf' ? (
                        <PictureAsPdf style={{ fontSize: 100, cursor: 'pointer' }} onClick={() => { openDataUri(data.imagen) }} />
                    ) : (getTypeFile(data.imagen) === 'mp3' || getTypeFile(data.imagen) === 'mpeg' || getTypeFile(data.imagen) === 'wav' || getTypeFile(data.imagen) === 'ogg' ? (
                        <>
                            <audio id="my-audio" src={data.imagen} style={{ width: '100%' }} crossOrigin="anonymous" controls="controls" autobuffer="autobuffer">
                                <source src={data.imagen} />
                            </audio>
                            {item?.transcripcion_activa &&
                                <TranscriptionButton
                                    nombreAudio={item.titulo}
                                    url={data.imagen}
                                    TextoOriginal={item.transcripcion}
                                    TextoEditado={item.transcripcion_editada}
                                    idGestion={item.id_gestion}
                                />
                            }
                        </>
                    ) : (getTypeFile(data.imagen) !== '' ? (<AttachFile style={{ fontSize: 100, cursor: 'pointer' }} onClick={() => { openDataUri(data.imagen) }} />) : null))
                    ))
                    ) : null}
                </div>
                {esCargueIndividual ? (
                    <div>
                        <Form.Control
                            name={`asesoria.${index}.descripcion`}
                            key={`${index} - ${idx}`}
                            ref={reg}
                            {...reg(`asesoria.${index}.${item.nombre}`, {
                                required: item.es_requerido
                            })}
                            // style={{ border: error ? '1px solid #dc3545' : null }}
                            disabled={esEditable}
                        />
                        <button className="confirmButton">Enviar</button>
                    </div>
                ) : null}
                <input
                    type={"hidden"}
                    name={`asesoria.${index}.${item.nombre}`}
                    value={JSON.stringify(data.imagen)}
                    ref={reg}
                    {...reg(`asesoria.${index}.${item.nombre}`, {
                        required: item.es_requerido
                    })}
                />
            </div>
        </Form.Group>
    );

}

export default Dropzone;
