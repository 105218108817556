import { useState, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { HelpSVG } from '../../components/icons/HelpSVG';
import SuccessIcon from '../../helpers/SuccessIcon/SuccessIcon'
import { ErrorSVG } from '../../components/icons/ErrorSVG'
import { WarningModalSVG } from '../../components/icons/WarningModalSVG'
import { Button, ButtonGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UnfoldLess, UnfoldMore } from '@material-ui/icons';
import './InformePanoramaRiesgos.scss';
import { RenderIcon } from '../../helpers/RenderIcon';


const PanelInformacion = ({ modal, setModal, titulo, dataGestion, tipoSolicitud, opcionesRegistro, estadoSolicitud, handleOpenModalNotification, opcionesFactura }) => {
  const { t } = useTranslation();
  const { data } = modal
  const cardRef = useRef(null);
  let { id } = useParams();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
    cardRef.current.scrollTop = 0;
  };

  return (
    <>
      {dataGestion?.informacion_dinamica?.length > 0 ?
        <Card className={`card__box__surface p-4 ${!isExpanded ? 'card__box__surface--compressed' : ''}`} ref={cardRef} id='tarjetaInformacionRegistro'>
          <Row style={{ alignItems: "center" }}>
            <Col xs={12} lg={3} className='text-center h-100'>
              <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: "24px" }} className='simpleGrayCard'>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  {dataGestion.conclusion.icono ? (
                    (dataGestion.conclusion.icono === 'exito'
                      ? <div>
                        <SuccessIcon />
                      </div>
                      : dataGestion.conclusion.icono === 'error'
                        ? <div>
                          <ErrorSVG />
                        </div>
                        : dataGestion.conclusion.icono === 'advertencia'
                          ? <div>
                            <WarningModalSVG />
                          </div>
                          : dataGestion.conclusion.icono === 'proceso'
                            ? <div>
                              <img alt='icono de carga' style={{ width: '95px' }} src="https://www.ciacosinte.site/cia2/static/media/rombo.svg" />
                              {/* <a href="https://icons8.com/icon/TXtRzGNlGEgi/rhombus-loader">Rhombus Loader icon by Icons8</a> */}
                            </div>
                            : dataGestion.conclusion.icono === '' &&
                            <div>
                              <WarningModalSVG />
                            </div>
                    )
                  ) : null}
                  {dataGestion.conclusion.valor !== ''
                    ?

                    <h3 className={'mt-2 etiqueta__conclusion'}>{dataGestion.conclusion.valor}</h3>
                    :
                    <h3 className={'mt-2'}>{t('formulario.label.verificar_estado')}</h3>

                  }
                </div>
                {dataGestion.generarPDF.visualizar === true &&
                  <div className='buttonGenerarPDF mt-3'>
                    <a href={dataGestion.generarPDF.link} rel='noreferrer' target='_blank'>
                      <button className='confirmButton button__lg'>
                        {t('botones.generar_pdf')}
                        <GetAppIcon />
                      </button>
                    </a>
                  </div>
                }
              </div>
            </Col>
            <Col xs={12} lg={9} >
              <Row>
                <Col md={12} className='contenedor__valores__informacion'>
                  {dataGestion?.informacion_dinamica?.map((item, index) => (
                    <div className='contenedor__informacion__registro flex-row flex-lg-column' key={index}>
                      <span className='titulo__informacion__registro mr-2 mr-lg-0'>{item.nombre}</span>
                      <span className='valor__informacion__registro'>
                        {item.icono && item.icono !== '' && (
                          <span className='valor__informacion__registro__icon'>
                            {RenderIcon({
                              icon: {
                                tipo: item.icono
                              }
                            })}
                          </span>
                        )}
                        <span className='valor__informacion__registro__text'>
                          {item.valor !== "" ? item.valor : t('formulario.label.sin_informacion')}
                        </span>
                      </span>
                    </div>
                  ))}
                </Col>
              </Row>
              {dataGestion.obs && dataGestion.obs.valor !== '' &&
                <Row>
                  <Col md={12}>
                    <Row style={{ display: 'flex', justifyContent: 'center' }} >
                      <Col lg={12}>
                        <hr style={{ margin: "0 0 1rem 0", color: "#94a3b8" }} />
                        <div
                          className='infoBasicaEditarRegistro'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'justify',
                            backgroundColor: '#f1f5f9',
                            color: '#1e293b',
                            padding: '8px 16px 16px 16px',
                            borderRadius: '7px',
                            border: '1px solid #cbd5e1'
                          }}
                        >
                          <p className='labelInfo' style={{ fontWeight: '900', marginBottom: '4px', fontSize: "16px", color: "#213265" }}>
                            {dataGestion.obs.titulo && dataGestion.obs.titulo !== ''
                              ? dataGestion.obs.titulo : t('formulario.label.observaciones')}
                          </p>
                          <p className='labelInfoContent' style={{ color: '#1e293b' }}>{dataGestion.obs.valor}</p>
                        </div>

                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              {opcionesRegistro?.length > 0 && (
                <Row id="container-botones-informe" style={{ paddingTop: "16px" }}>
                  <Col xs={12} className='p-0'>
                  <hr style={{ margin: "0 0 1rem 0", color: "#94a3b8" }} />
                    <ButtonGroup aria-label="text group">
                      {(opcionesRegistro.map((item, index) => (
                        <button
                          className='btnAccionesInfoGeneral'
                          style={{ opacity: !item.deshabilitado ? 1 : 0.25 }}
                          disabled={item.deshabilitado}
                          onClick={() => { handleOpenModalNotification(item, index, 'Esta seguro de cambiar el estado de esa gestion?', 'cambio_estado_gestion') }}
                        >
                          {item.valor}
                        </button>
                      )))}
                    </ButtonGroup>
                  </Col>
                </Row>
              )}
            </Col>

          </Row>
          <div className={`icon__button ${isExpanded ? 'icon__button--absolute' : ''}`}>
            <button onClick={() => handleExpand()} title={isExpanded ? 'Contraer' : 'Expandir'}>
              {isExpanded ? <UnfoldLess /> : <UnfoldMore />}
            </button>
          </div>
        </Card> :

        Object.keys(dataGestion).length ? (
          <>
            <Card className='cardGeneral card__box__surface'>
              <Card.Body>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col id='columnaIconoEstado' xs={12} md={3} className='text-center'>
                    {dataGestion.conclusion !== {} ? (
                      <div style={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'space-around' }}>
                        <div
                          style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'space-around',
                            justifyContent: 'space-around'

                          }}
                        >
                          {dataGestion.conclusion.icono ? (
                            (dataGestion.conclusion.icono === 'exito'
                              ? <div>
                                <SuccessIcon />
                              </div>
                              : dataGestion.conclusion.icono === 'error'
                                ? <div>
                                  <ErrorSVG />
                                </div>
                                : dataGestion.conclusion.icono === 'advertencia'
                                  ? <div>
                                    <WarningModalSVG />
                                  </div>
                                  : dataGestion.conclusion.icono === 'proceso'
                                    ? <div>
                                      <img alt='icono de carga' style={{ width: '95px' }} src="https://www.ciacosinte.site/cia2/static/media/rombo.svg" />
                                      {/* <a href="https://icons8.com/icon/TXtRzGNlGEgi/rhombus-loader">Rhombus Loader icon by Icons8</a> */}
                                    </div>
                                    : dataGestion.conclusion.icono === '' &&
                                    <div>
                                      <WarningModalSVG />
                                    </div>
                            )
                          ) : null}
                          {dataGestion.conclusion.valor !== ''
                            ?

                            <h3>{dataGestion.conclusion.valor}</h3>
                            :
                            <h3>{t('formulario.label.verificar_estado')}</h3>

                          }
                        </div>
                        <br />
                        {dataGestion.generarPDF.visualizar === true &&
                          <div className='buttonGenerarPDF'>
                            <a href={dataGestion.generarPDF.link} rel='noreferrer' target='_blank'>
                              <button className='confirmButton'>
                                {t('botones.generar_pdf')}
                                <GetAppIcon />
                              </button>
                            </a>
                          </div>
                        }

                      </div>
                    ) : (
                      <div>
                        <WarningModalSVG />
                        <h3>Verificar estado </h3>
                      </div>
                    )}
                  </Col>
                  <Col xs={6} lg={4} md={5} className='listPopUp mt-3'>
                    {dataGestion?.estado?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>{t('formulario.label.estado')}:</p>
                        <p className='labelInfoContent'>{estadoSolicitud}</p>
                      </div>
                    }
                    {dataGestion?.fecha_solicitud?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>{dataGestion.fecha_solicitud.nombre}</p>
                        <p className='labelInfoContent'>{dataGestion.fecha_solicitud.valor}</p>
                      </div>

                    }
                    {dataGestion?.perfil?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{dataGestion.perfil.tipo}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.perfil.nombre}
                        </p>
                      </div>
                    }
                    {dataGestion?.analista?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{dataGestion.analista.nombre}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.analista.valor}
                        </p>
                      </div>
                    }
                    {dataGestion?.tipo_solicitud &&
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>{t('formulario.label.tipo_solicitud')}:</p>
                        <p className='labelInfoContent'>{dataGestion.tipo_solicitud}</p>
                      </div>
                    }
                    {dataGestion?.fecha_finalizacion?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p className='labelInfo'>{t('formulario.label.fecha_finalizacion')}:</p>
                        <p className='labelInfoContent'>{dataGestion.fecha_finalizacion.valor}</p>
                      </div>
                    }
                  </Col>
                  <Col xs={6} lg={4} md={3} className='listPopUp mt-3'>
                    {dataGestion?.identificador?.visualizar === true
                      ?
                      <div className='infoBasicaEditarRegistro'>
                        <p>{dataGestion.identificador.tipo}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.identificador.valor}
                        </p>
                      </div>
                      : null
                    }
                    <div className='infoBasicaEditarRegistro'>
                      <p>ID:</p>
                      <p className='labelInfoContent'>
                        {id}
                      </p>
                    </div>
                    {dataGestion?.estado_cos?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{t('formulario.label.estado_cos')}</p>
                        <p className='labelInfoContent'>
                          {estadoSolicitud}
                        </p>
                      </div>
                    }
                    {dataGestion?.fecha_cosinte?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{dataGestion.fecha_cosinte.nombre}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.fecha_cosinte.valor}
                        </p>
                      </div>
                    }
                    {dataGestion?.fecha_limite?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{t('formulario.label.fecha_limite')}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.fecha_limite.valor}
                        </p>
                      </div>
                    }
                    <div className='infoBasicaEditarRegistro'>
                      <p>{dataGestion?.tipo_proceso?.nombre}</p>
                      <p className='labelInfoContent'>
                        {dataGestion.tipo_proceso.valor}
                      </p>
                    </div>
                    {dataGestion?.responsable?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{dataGestion.responsable.nombre}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.responsable.valor}
                        </p>
                      </div>
                    }
                    {dataGestion?.acompanante?.visualizar === true &&
                      <div className='infoBasicaEditarRegistro'>
                        <p>{dataGestion.acompanante.nombre}</p>
                        <p className='labelInfoContent'>
                          {dataGestion.acompanante.valor}
                        </p>
                      </div>
                    }
                  </Col>
                </Row>
                {dataGestion?.obs && dataGestion?.obs?.valor !== '' &&
                  <Row xs={11} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Col lg={10}>
                      <hr />
                      <div
                        className='infoBasicaEditarRegistro'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'justify',
                          backgroundColor: '#213265',
                          color: 'white',
                          padding: '6px',
                          borderRadius: '7px'
                        }}
                      >
                        <p className='labelInfo' style={{ fontWeight: '900', marginBottom: '8px' }}>{t('formulario.label.observaciones')}</p>
                        <p className='labelInfoContent' style={{ color: 'white' }}>{dataGestion?.obs?.valor}</p>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                }
                <Row id="container-botones-informe">
                  <br />
                  <Col xs={12}>
                    <ButtonGroup aria-label="text group">
                      {opcionesRegistro.length > 0 && (opcionesRegistro.map((item, index) => (
                        <button
                          className='btnAccionesInfoGeneral'
                          style={{ opacity: !item.deshabilitado ? 1 : 0.25 }}
                          disabled={item.deshabilitado}
                          // className="button-group-informe"
                          onClick={() => { handleOpenModalNotification(item, index, 'Esta seguro de cambiar el estado de esa gestion?', 'cambio_estado_gestion') }}
                        >
                          {item.valor}
                        </button>
                      )))}
                    </ButtonGroup>
                  </Col>
                  {/* <Col xs={12}>
                                    <ButtonGroup  aria-label="text group">
                                        { (opcionesFactura && opcionesFactura.length > 0) && (opcionesFactura.map((item, index) => (
                                            (item.tipo === 'accion' ? (
                                                <button
                                                    className='btnAccionesInfoGeneral'
                                                    style={{ opacity: !item.deshabilitado ?  1 : 0.25, cursor: 'pointer' }}
                                                    disabled={item.deshabilitado}
                                                    // className="button-group-informe"
                                                    // onClick={() => {handleOpenModalNotification(item, index, 'Esta seguro de cambiar el estado de esa gestion?', 'cambio_estado_gestion')}}
                                                >
                                                    {item.label}
                                                </button>
                                            ) : (item.tipo === 'enlace' ? (
                                                    <a
                                                        href={item.enlace}
                                                        className='btnAccionesInfoGeneral'
                                                        style={{ opacity: !item.deshabilitado ?  1 : 0.25 }}
                                                        disabled={item.deshabilitado}
                                                        target="_blank"
                                                    >
                                                        {item.label}
                                                    </a>
                                                ) : null )
                                            ))
                                        ))}
                                    </ButtonGroup>
                                </Col> */}
                </Row>
              </Card.Body>
            </Card>
          </>
        ) : null}
      {/*<p style={{ margin: '15px 0' }} >{tipoSolicitud}</p>*/}
    </>
  );
}

export default PanelInformacion;
