import React from 'react'

export const WarningModalSVG = ({width, height}) => {
  return (
<svg xmlns="https://www.w3.org/2000/svg"  version="1.1" width={width ? width:"150"} height={height ? height:"150"} x="0" y="0" viewBox="0 0 512 512"><g>
<g xmlns="https://www.w3.org/2000/svg">
	<g>
		<path d="M501.362,383.95L320.497,51.474c-29.059-48.921-99.896-48.986-128.994,0L10.647,383.95    c-29.706,49.989,6.259,113.291,64.482,113.291h361.736C495.039,497.241,531.068,433.99,501.362,383.95z M256,437.241    c-16.538,0-30-13.462-30-30c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30C286,423.779,272.538,437.241,256,437.241z     M286,317.241c0,16.538-13.462,30-30,30c-16.538,0-30-13.462-30-30v-150c0-16.538,13.462-30,30-30c16.538,0,30,13.462,30,30    V317.241z" fill="#e4a731" data-original="#000000"/>
	</g>
</g>
<g xmlns="https://www.w3.org/2000/svg">
</g>
</g>
</svg>

  )
}
