import { useState } from "react";
import { Form } from "react-bootstrap";
import { BtnAyuda } from "./BtnAyuda";
import CurrencyInput from "react-currency-input-field";
import CloseIcon from '@material-ui/icons/Close';
import { CloseSmall } from "../../components/icons/CloseSmallSVG";


const Number = ({
  item,
  handleInputChange,
  index,
  idx,
  reg,
  nombre,
  error,
  esEditable,
  getSumValues,
  getTotal,
}) => {
  const [numberValue, setNumberValue] = useState(item.valor);

  const getValue = (e) => {
    //Si se esta borrando el valor del input y solo queda un digito, se pone en 0
    if (e === undefined) {
      setNumberValue(0);
    } else {
      setNumberValue(e);
    }
    ;
  };

  return (
    <Form.Group style={{ display: "block" }} >
      <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
        <Form.Label style={{ color: item.label_color ? item.label_color : "#000", fontSize: item.font_size ? item.font_size : "" }}>{item.titulo}{item.es_requerido === true && '*'}</Form.Label>
        {item.ayuda &&
            <BtnAyuda ayuda={item.ayuda} />
        }
      </div>
      {/* <Form.Control
                defaultValue={parseInt(item.valor)}
                type="number"
                name={`asesoria.${index}.${item.nombre}`}
                key={`${index} - ${idx}`}
                ref={reg}
                {...reg(`asesoria.${index}.${item.nombre}`, {
                    required: item.es_requerido
                })}
                style={{ border: error ? '1px solid #dc3545' : null }}
                onChange={(e) => { getSumValues(e, index)}}
                onBlur={() => {getTotal(index)}}
                disabled={esEditable}
            /> */}
      <div style={{ backgroundColor: "#fcfcfc", padding: "0px" }}>
        <CurrencyInput
          id="input_decimales"
          key={`${index} - ${idx}`}
          defaultValue={numberValue}
          className="form-control"
          decimalsLimit={6}
          name={`asesoria.${index}.${item.nombre}`}
          intlConfig={{
            locale: "es-CO",
            // currency: 'COP'
          }}
          style={{ border: error ? "1px solid #dc3545" : null }}
          onValueChange={getValue}
          disabled={esEditable}
          value={numberValue}
        />

        <input
          type="hidden"
          name={`asesoria.${index}.${item.nombre}`}
          value={numberValue}
          ref={reg}
          {...reg(`asesoria.${index}.${item.nombre}`, {
            required: item.es_requerido,
          })} />
        {/* <CloseSmall fontSize="small"
          style={{ cursor: "pointer", color: "#707070", marginRight: "8px", position: "absolute", right: "10" }}
          onClick={() => {
            document.getElementById("input_decimales").value = "0";
            // document.getElementById('select_busqueda').value = '0';

            setNumberValue(0)
          }} /> */}
        <CloseIcon
          fontSize="small"
          style={{ cursor: "pointer", color: "#707070", position: "absolute", right: "24px", fontSize: '100%', top: '37px'}}
          onClick={() => {
            document.getElementById("input_decimales").value = "0";
            // document.getElementById('select_busqueda').value = '0';
            setNumberValue(0)
          }}
        />
      </div>

      {error ? (
        error.type === "required" ? (
          <span className="inputsInvalid">Este campo es requerido</span>
        ) : null
      ) : null}
    </Form.Group>
  );
};

export default Number;
