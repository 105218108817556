import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import TarjetaInformacion from './components/TarjetaInformacion';
import NestedAccordion from './components/NestedAccordion';
import ModalCRUD from '../../common/ModalCRUD';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Plus, NotepadTextDashed } from 'lucide-react';
import { Skeleton } from 'primereact/skeleton';
import Loader from '../../icons/Loader';


/**
 * Componente que se encarga de mostrar la estructura de un árbol dinámicamente
 * Permite realizar acciones como ver, editar, eliminar y agregar elementos
 * El árbol es un accordion que puede tener n niveles (recursivo)
 * @param {number} idSegundoNivel: id del segundo nivel del gestor dinámico
 * @param {Object} data: Estructura del árbol
 * @param {Function} getEstructura: Función que obtiene la estructura del árbol
 * @returns {React.Component} ArbolDinamico
 */

export default function ArbolDinamico({ idSegundoNivel, data, getEstructura, isLoading }) {
  console.log(getEstructura+"GetEstructura1");
  const { id } = useParams() //Id del registro
  const [showModal, setShowModal] = useState(false); //Estado que controla la apertura del modal
  const [submitData, setSubmitData] = useState({}); //Datos que se enviaran al modal
  const [tituloModal, setTituloModal] = useState(''); //Titulo del modal
  const { t } = useTranslation();

  /**
   * Funcion que se encarga de abrir el modal para realizar una acción
   * @param {*} item Array con los ids de los padres en el árbol
   * @param {("VER"|"EDITAR"|"ELIMINAR"|"AGREGAR")} accion Acción a realizar
   * @param {number} nivel Nivel del árbol
   * @param {string} titulo Titulo del registro a mostrar en el modal
   */

  const onAction = (item, accion, nivel, titulo) => {
    setShowModal(true)
    setSubmitData(
      {
        hash: window.location.hash,
        id_segundo_nivel: idSegundoNivel,
        id_padre: item,
        id,
        accion,
        nivel: nivel
      }
    )
    setTituloModal(titulo)
  }

  /**
   * Función que controla la apertura del modal para editar la información del registro
   */

  const onEditarInformacion = () => {
    setShowModal(true)
    setSubmitData(
      {
        hash: window.location.hash,
        id_segundo_nivel: idSegundoNivel,
        id,
        accion: 'EDITAR_INFORMACION'
      }
    )
    setTituloModal(t('formulario.label.editar_registro'));
  }



  return (
    <>
      {isLoading ? (<Loader></Loader>) : (<>
        <Card className='shadow-1 rounded-2xl p-3'>
          <Card.Body>
            {data?.informacion &&
              <TarjetaInformacion
                titulo={data.informacion.titulo}
                items={data.informacion.items}
                pdf={data.pdf}
                permisos={data.informacion.permisos}
                onEditar={onEditarInformacion} />
            }
            <br></br>
            {<NestedAccordion
              items={data?.items}
              onView={onAction}
              onEdit={onAction}
              onDelete={onAction}
              onAdd={onAction}
              permisos={data?.permisos}
            />}
            {(data?.items === undefined || (data.items && data.items.length === 0)) &&
              <div className='text-center empty__card'>
                <div className='icon'>
                  <NotepadTextDashed size='48' />
                </div>
                <span>{t('formulario.label.no_registros')}</span>
              </div>
            }
            {data?.permisos?.[0]?.agregar &&
              <div className='text-end mt-3'>
                <Button size='small' onClick={() => onAction(null, 'AGREGAR', 0, data.permisos?.[0]?.agregar_l ? data.permisos?.[0]?.agregar_l : t('botones.agregar_registro'))} icon={<Plus size='16' />} className='p-button-primary'>
                  {data.permisos?.[0]?.agregar_l ? data.permisos?.[0]?.agregar_l : t('botones.agregar_registro')}
                </Button>
              </div>
            }
          </Card.Body>
        </Card>
        <ModalCRUD
          isOpen={showModal}
          setIsOpen={setShowModal}
          submitData={submitData}
          getFormData={submitData}
          viewMode={submitData.accion === 'VER' ? true : false}
          deleteMode={submitData.accion === 'ELIMINAR' ? true : false}
          refresh={getEstructura}
          urlEstructura={data?.enlaces.estructura}
          urlEnvio={data?.enlaces.envio}
          titulo={tituloModal}
        />
      </>)}
    </>
  )
}
