import React, { Suspense } from 'react'
import { Routes } from './routes/Routes'
import { Provider } from 'react-redux';
import generateStore from './store/store';

/* Traducciones */
import './i18n';

import '@amcharts/amcharts4/charts';

import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { JoyrideProvider} from './context/Joyride';

am4core.useTheme(am4themes_animated);

am4core.addLicense("CH275678482");




export const App = () => {
  const store = generateStore()
  return (

    <Provider store={store}>
      <JoyrideProvider>
        <Suspense fallback={null}>
          <Routes />
        </Suspense>
      </JoyrideProvider>
    </Provider >
  )
}
